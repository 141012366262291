import { transformImage } from 'theme/filters/media'

export default {
  data () {
    return {
      placeholder: '/assets/placeholder.jpg'
    }
  },
  filters: {
    transformImage
  },
  computed: {
    wrapperClasses () {
      return this.item.wrapper_class_names ? this.item.wrapper_class_names.split(' ') : false
    }
  },
  methods: {
    linkProps (item) {
      let linktype = item.linktype

      if (linktype === 'url') {
        return {
          is: 'a',
          href: item.url,
          target: '_blank',
          rel: 'noopener'
        }
      }

      return {
        is: 'router-link',
        to: '/' + item.cached_url
      }
    },
    thumbnail (image, option) {
      return transformImage(image, option)
    }
  }
}
