<template>
  <div :class="wrapperClasses">
    <div class="carousel-wrap relative">
      <no-ssr>
        <carousel
          :per-page="1"
          :mouse-drag="false"
          :navigation-enabled="true"
          :autoplay="true"
          :autoplayTimeout="4000"
          :autoplayHoverPause="true"
          pagination-active-color="#000000"
          pagination-color="#9b9b9b"
          :pagination-padding="0"
          :pagination-size="6"
          :loop="true"
          :dots-labels="dotsLabels"
          navigation-next-label="<i class='carousel-icons next-icon'></i>"
          navigation-prev-label="<i class='carousel-icons prev-icon'></i>"
          ref="carousel"
          :speed="carouselTransitionSpeed"
          @pageChange="pageChange"
          class="carousel relative mx-auto my-0"
          :class="currentPage < 6 ? 'randco-active' : ''"
        >
          <slide
            v-for="(slide, index) in brandsSlides"
            :key="index"
          >
            <div class="flex items-center justify-center w-full text-center relative mx-auto my-0">
              <component v-bind="linkProps(slide.link)">
                <img :src="slide.img" :alt="slide.alt">
              </component>
            </div>
          </slide>
        </carousel>
      </no-ssr>
    </div>
  </div>
</template>

<script>
import NoSSR from 'vue-no-ssr'
import {
  Blok
} from 'src/modules/vsf-storyblok-module/components'
import StoryblokItem from 'theme/mixins/blocks/StoryblokItem'
import Carousel from 'theme/components/theme/blocks/Home/Carousel/Carousel'
import { mapGetters } from 'vuex'

export default {
  extends: Blok,
  name: 'GeneralSlider',
  components: {
    Carousel,
    'Slide': () => import('vue-carousel').then(Slider => Slider.Slide),
    'no-ssr': NoSSR
  },
  mixins: [StoryblokItem],
  data() {
    return {
      currentPage: 0,
      carouselTransitionSpeed: 250,
      currentPage: 0,
      hideImageAtIndex: null,
      brandsSlides: [],
      brands: {
        rco: 'R+Co',
        v76: 'V76 by Vaughn',
        sc: 'Smith & Cult'
      }
    }
  },
  beforeMount () {
    this.getBrands.forEach(brand => {
      let slidesByBrand = this.item.slides.filter(slide => slide.brand === brand.code)
      this.brandsSlides.push(...slidesByBrand)
    })

    this.brandsSlides.forEach(slide => {
      slide['label'] = this.brands[slide.brand]
    })
  },
  computed: {
    ...mapGetters({
      getBrands: 'brands/getBrands'
    }),
    dotsLabels() {
      let tempArray = []

      for (let i = 0; i < this.brandsSlides.length; i++) {
        if (i === 0) {
          tempArray.push(this.brandsSlides[i]['label'])
          continue
        }
        this.brandsSlides[i]['label'] !== this.brandsSlides[i - 1]['label'] ? tempArray.push(this.brandsSlides[i]['label']) : tempArray.push('')
      }

      return tempArray
    }
  },
  methods: {
    pageChange(index) {
      this.currentPage = index
      this.hideImageAtIndex = null
    },
    linkProps (item) {
      const splitUrl = item.url ? item.url.split('/') : []

      if ((splitUrl.length && splitUrl[0].includes('http')) || item.target === '_blank') {
        return {
          is: 'a',
          href: item.url,
          target: '_blank',
          rel: 'noopener'
        }
      }

      return {
        is: 'router-link',
        to: item.cached_url
      }
    }
  }
}

</script>
<style lang="scss" scoped>
.carousel-wrap {
  margin-bottom: 100px;

  &:after {
    @apply absolute top-0 w-full h-full;
    background-color: #708090;
    background-size: 100%;
    content: '';
    left: 93%;
    z-index: -1;
  }

  &:before {
    @apply absolute top-0 w-full h-full;
    background-color: #708090;
    background-size: 100%;
    content: '';
    left: 0;
    z-index: -1;
  }
}

.carousel {
  &::v-deep {
    .VueCarousel-pagination {
      @apply absolute;
      bottom: -60px;
    }

    .VueCarousel-wrapper {
      @apply overflow-visible mx-auto;
      max-width: 86%;
    }

    .VueCarousel-navigation {
      @apply absolute;
      transform: translateY(-50%);
      left: 0;
      right: 15px;
      top: 50%;

      @screen md {
        left: 30px;
        right: 60px;
      }
    }

    .VueCarousel-dot-container {
      @apply relative flex items-center justify-between w-full;
      max-width: 250px;

      &:before {
        @apply absolute bg-grey-37;
        content: '';
        height: 1px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% - 20px);
        z-index: -1;
      }
    }

    .VueCarousel-dot {
      @apply flex items-center justify-center border-solid border-transparent border-4 rounded-full text-grey-37 relative;

      &:not([label~='R+Co']) {
        &:not([label~='V76']) {
          &:not([label~='Smith']) {
            margin-left: -50px;
          }
        }
      }

      &.VueCarousel-dot--active {
        @apply text-black;
      }

      &:after {
        @apply absolute uppercase tracking-average leading-thick text-h6;
        content: attr(label);
        left: 50%;
        top: 15px;
        transform: translateX(-50%);
        width: 100px;
      }

      &--active {
        @apply border-4 border-solid border-black rounded-full;

        .VueCarousel-dot-button {
          @apply relative outline-none;

          &:before {
            @apply absolute rounded-full bg-white;
            content: '';
            height: 3px;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 3px;
          }
        }
      }
    }

    .carousel-icons {
      @apply absolute z-2 text-white cursor-pointer text-big;
      top: 50%;

      &:before {
        @apply absolute;
        content: "";
        width: 17px;
        height: 17px;
        border-left: 2px solid white;
        border-bottom: 2px solid white;
        transform: rotate(45deg);
        @screen md {
          width: 34px;
          height: 34px;
        }
      }

      &.next-icon {
        &:before {
          transform: rotate(225deg);
        }
      }
    }
  }
}

</style>
